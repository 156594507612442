(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("./EMFJS.bundle.js"), require("./WMFJS.bundle.js"));
	else if(typeof define === 'function' && define.amd)
		define(["./EMFJS.bundle.js", "./WMFJS.bundle.js"], factory);
	else if(typeof exports === 'object')
		exports["RTFJS"] = factory(require("./EMFJS.bundle.js"), require("./WMFJS.bundle.js"));
	else
		root["RTFJS"] = factory(root["EMFJS"], root["WMFJS"]);
})(this, (__WEBPACK_EXTERNAL_MODULE_EMFJS__, __WEBPACK_EXTERNAL_MODULE_WMFJS__) => {
return 